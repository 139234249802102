import React, { useCallback, useEffect } from 'react';
import loadable from '@loadable/component';
import { useTranslation, useEnvironment, useFedopsLogger, Trans, useErrorMonitor } from '@wix/yoshi-flow-editor';
import { IntegrationData, VerticalStatusContent, StatusProps, CommonProps } from '../../../../types/common';
import { PackagePickerInteractions } from '../../../../types/PackagePickerFedops';
import { shortDate, ymdToDate } from '../../../../utils/date';
import { toError } from '../../../../utils/errors';
import { openBillingPage } from '../../../../utils/openBillingPage';
import { getPageType, PageType } from '../../../../utils/pageType';
import { FocusedHeading } from '../FocusedHeading';
import { classes } from './Status.st.css';

const RicosViewer = loadable(() => import('./Ricos'));

interface StatusWidgetProps extends StatusProps, CommonProps {
  ownerDemo?: boolean;
  integrationData: IntegrationData;
}

const Status: React.FC<StatusWidgetProps> = (props) => {
  const fedops = useFedopsLogger();

  React.useEffect(() => {
    fedops.interactionEnded(PackagePickerInteractions.StatusPageLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage = () => {
    switch (getPageType(props, props.integrationData)) {
      default:
      case PageType.DEFAULT_SUCCESS:
        return <Success {...props} />;
      case PageType.ERROR:
        return <Error error={props.translatedError} onButtonClick={props.navigateBackToCheckout} />;
      case PageType.OWNER_DEMO:
        return <OwnerDemo {...props} />;
      case PageType.VERTICAL_SUCCESS:
        return <VerticalSuccess {...props} />;
    }
  };

  return (
    <div data-hook="status-page" className={classes.wrapper}>
      {renderPage()}
    </div>
  );
};

export default Status;

interface SuccessProps {
  title: string;
  message?: object;
  startDate?: string;
  cta: string;
  onCtaClick(): void;
}

const Success: React.FC<StatusWidgetProps> = ({
  settings,
  startDate,
  navigateFromStatusPage,
  biThankYouPageCtaButtonClick,
  biThankYouPageOnLoad,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const errorMonitor = useErrorMonitor();

  useEffect(() => {
    biThankYouPageOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseMessage = useCallback(
    (message?: string) => {
      if (message) {
        try {
          return JSON.parse(message);
        } catch (e) {
          errorMonitor.captureException(toError(e));
        }
      }
    },
    [errorMonitor],
  );

  const props: SuccessProps = React.useMemo(
    () => ({
      title: settings?.title ?? t('sp.success-title'),
      startDate,
      message: parseMessage(settings?.message),
      cta: settings?.buttonText ?? t('sp.success-action'),
      onCtaClick: () => {
        biThankYouPageCtaButtonClick();
        navigateFromStatusPage();
      },
    }),
    [t, startDate, navigateFromStatusPage, biThankYouPageCtaButtonClick, settings, parseMessage],
  );
  return React.createElement(isMobile ? SuccessMobile : SuccessDesktop, props);
};

const SuccessMobile: React.FC<SuccessProps> = ({ title, startDate, cta, onCtaClick, message }) => {
  return (
    <div>
      <h2 data-hook="status-success-title" className={classes.titleMobile}>
        {title}
      </h2>
      <SuccessText className={classes.textMobile} startDate={startDate} message={message} />
      <div className={classes.buttonsContainer}>
        <button data-hook="status-success-button" onClick={onCtaClick} className={classes.buttonMobile}>
          {cta}
        </button>
      </div>
    </div>
  );
};

const SuccessDesktop: React.FC<SuccessProps> = ({ title, startDate, cta, onCtaClick, message }) => {
  return (
    <div>
      <FocusedHeading data-hook="status-success-title" className={classes.titleDesktop}>
        {title}
      </FocusedHeading>
      <SuccessText className={classes.textDesktop} startDate={startDate} message={message} />
      <div className={classes.buttonsContainer}>
        <button data-hook="status-success-button" onClick={onCtaClick} className={classes.buttonDesktop}>
          {cta}
        </button>
      </div>
    </div>
  );
};

type SuccessTextProps = Pick<SuccessProps, 'startDate' | 'message'> & { className: string };

const SuccessText: React.FC<SuccessTextProps> = React.memo(({ startDate, message, className }) => {
  const { t } = useTranslation();
  const text = startDate
    ? t('sp.success-text-with-date', {
        date: shortDate(ymdToDate(startDate)),
        interpolation: { escapeValue: false },
      })
    : t('sp.success-text');
  const body = message ? <RicosViewer message={message} fallbackMessage={text} /> : <p>{text}</p>;
  return (
    <div className={className} data-hook="status-success-text">
      {body}
    </div>
  );
});

interface ErrorProps {
  error?: string;
  onButtonClick(): void;
}

const Error: React.FC<ErrorProps> = ({ error, onButtonClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const onClick = useCallback(() => onButtonClick(), [onButtonClick]);
  return (
    <div>
      <FocusedHeading data-hook="status-error-title" className={isMobile ? classes.titleMobile : classes.titleDesktop}>
        {error || t('sp.error-title')}
      </FocusedHeading>
      <button
        data-hook="status-error-button"
        className={isMobile ? classes.buttonMobile : classes.buttonDesktop}
        onClick={onClick}
      >
        {t('sp.error-action')}
      </button>
    </div>
  );
};

interface OwnerDemoProps {
  title: string;
  body: React.ReactNode;
  cta: string;
  onCtaClick(): void;
}

const OwnerDemo: React.FC<StatusWidgetProps> = ({ metaSiteId, biUpgradeReferralClick }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const props: OwnerDemoProps = React.useMemo(
    () => ({
      title: t('sp.owner-demo.title'),
      body: <Trans i18nKey="sp.owner-demo.text" />,
      cta: t('sp.owner-demo.cta'),
      onCtaClick: () => {
        biUpgradeReferralClick('thank-you-page');
        metaSiteId && openBillingPage(metaSiteId);
      },
    }),
    [t, metaSiteId, biUpgradeReferralClick],
  );
  return React.createElement(isMobile ? OwnerDemoMobile : OwnerDemoDesktop, props);
};

const OwnerDemoMobile: React.FC<OwnerDemoProps> = ({ title, body, cta, onCtaClick }) => {
  return (
    <div data-hook="owner-demo">
      <FocusedHeading as="h2" className={classes.titleMobile} data-hook="status-page-title">
        {title}
      </FocusedHeading>
      <div data-hook="status-page-text" className={classes.textMobile}>
        {body}
      </div>
      <button data-hook="status-page-upgrade" className={classes.buttonMobile} onClick={onCtaClick}>
        {cta}
      </button>
    </div>
  );
};

const OwnerDemoDesktop: React.FC<OwnerDemoProps> = ({ title, body, cta, onCtaClick }) => {
  return (
    <div data-hook="owner-demo">
      <FocusedHeading className={classes.titleDesktop} data-hook="status-page-title">
        {title}
      </FocusedHeading>
      <p data-hook="status-page-text" className={classes.textDesktop}>
        {body}
      </p>
      <button data-hook="status-page-upgrade" className={classes.buttonDesktop} onClick={onCtaClick}>
        {cta}
      </button>
    </div>
  );
};

interface VerticalSuccessProps extends VerticalStatusContent {
  onCtaClick(): void;
}

const VerticalSuccess: React.FC<StatusWidgetProps> = ({
  integrationData,
  navigateFromStatusPage,
  biThankYouPageCtaButtonClick,
  biThankYouPageOnLoad,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  useEffect(() => {
    biThankYouPageOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props: VerticalSuccessProps = React.useMemo(
    () => ({
      titleText: t('sp.vertical.success-title'),
      buttonText: t('sp.vertical.success-action'),
      ...integrationData.verticalStatusContent,
      onCtaClick: () => {
        biThankYouPageCtaButtonClick();
        navigateFromStatusPage();
      },
    }),
    [t, integrationData.verticalStatusContent, navigateFromStatusPage, biThankYouPageCtaButtonClick],
  );
  return React.createElement(isMobile ? VerticalSuccessMobile : VerticalSuccessDesktop, props);
};

const VerticalSuccessMobile: React.FC<VerticalSuccessProps> = ({ titleText, buttonText, contentText, onCtaClick }) => {
  return (
    <div>
      <FocusedHeading as="h2" data-hook="status-success-vertical-title" className={classes.titleMobile}>
        {titleText}
      </FocusedHeading>
      {contentText && (
        <div data-hook="status-success-vertical-content" className={classes.textMobile}>
          {contentText}
        </div>
      )}
      <button className={classes.buttonMobile} onClick={onCtaClick} data-hook="status-success-vertical-button">
        {buttonText}
      </button>
    </div>
  );
};

const VerticalSuccessDesktop: React.FC<VerticalSuccessProps> = ({ titleText, buttonText, contentText, onCtaClick }) => {
  return (
    <div>
      <FocusedHeading data-hook="status-success-vertical-title" className={classes.titleDesktop}>
        {titleText}
      </FocusedHeading>
      {contentText && (
        <p className={classes.textDesktop} data-hook="status-success-vertical-content">
          {contentText}
        </p>
      )}
      <button className={classes.buttonDesktop} onClick={onCtaClick} data-hook="status-success-vertical-button">
        {buttonText}
      </button>
    </div>
  );
};
