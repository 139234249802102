import React from 'react';
import type { AppWidgetProps } from '../../../types/common';
import CssVars from '../../PackagePicker/CssVars';
import ThankYouWidget from './ThankYouWidget';

export default function (props: AppWidgetProps) {
  return (
    <>
      <ThankYouWidget {...props} />
      <CssVars id={props.host.id} style={props.host.style} />
    </>
  );
}
